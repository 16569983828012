import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [~8],
		"/admin": [9,[2]],
		"/admin/users": [~10,[2]],
		"/admin/users/[id]": [~11,[2]],
		"/checkout": [~12,[3]],
		"/checkout/success": [~13,[3]],
		"/collections": [~14,[4],[5]],
		"/collections/new": [16,[4],[5]],
		"/collections/[id]": [~15,[4],[5]],
		"/link/[id]": [~17],
		"/login": [~18],
		"/logout": [~19],
		"/photos": [~20,[6]],
		"/photos/[id]": [~21,[6]],
		"/privacy-policy": [22],
		"/profile": [~23],
		"/reset-password": [~24],
		"/sign-up": [~25],
		"/terms-conditions": [26],
		"/wallet": [~27,[7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';